import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../MapScreen/PlannerDetailsDrawer";
import {
  convertTimestampToFormattedDateForEntryExit,
  entryExitTimeDifference,
  getDateForApi,
} from "../../../utils/common";
import { getCustomColor } from "../../../utils/theme";
import { useLazyGetOrganizationPropertyStatsQuery } from "../../../services/Properties";
import { useSelector } from "react-redux";

const EntryExitTable = ({ setTotalVisits, isSmallScreen }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const [propertyStats, setPropertyStats] = useState(false);
  const [propertyStatsCombinedData, setPropertyStatsCombinedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedDevicesGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesGeoJson
  );
  const devicesDashboardLocationValue = useSelector(
    (state) => state.gisDashboard.devicesDashboardLocationValue
  );
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const [getOrganizationPropertyStats] =
    useLazyGetOrganizationPropertyStatsQuery();

  const fetchPropertyStats = async (deviceId, url = null, accumulatedData = []) => {
    try {
      setIsLoading(true);
      const response = await getOrganizationPropertyStats({
        orgName: user?.organization,
        location: `${devicesDashboardLocationValue?.key}`,
        date: getDateForApi(selectedDateForDevices),
        deviceId: deviceId,
        url,
      });
      if (response?.data) {
        setIsLoading(false);
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );
        if (nextUrl) {
          return fetchPropertyStats(deviceId, nextUrl, combinedData);
        } else {
          setPropertyStatsCombinedData((prevData) => ({
            type: "FeatureCollection",
            features: prevData?.features
              ? [...prevData?.features, ...combinedData]
              : combinedData,
          }));
        }
       
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(()=>{
    if(propertyStatsCombinedData){
      const stats = [];
      let start = null;
      let end = null;

      propertyStatsCombinedData?.features.forEach((feature, index) => {
        const { start_time, end_time } = feature?.properties;
        const startTime = new Date(start_time);
        const endTime = new Date(end_time);

        if (index > 0) {
          const previousEndTime = new Date(
            propertyStatsCombinedData?.features[index - 1].properties.end_time
          );
          const timeDiffInMinutes =
            (startTime - previousEndTime) / (1000 * 60); // Convert milliseconds to minutes

          if (timeDiffInMinutes > 10) {
            stats.push({ start_time: start, end_time: end });
            start = startTime;
            end = endTime;
          } else {
            end = endTime;
          }
        } else {
          start = startTime;
          end = endTime;
        }
      });

      if (start && end) {
        stats.push({ start_time: start, end_time: end });
      }

      setPropertyStats(stats);
      setTotalVisits(stats);
    }
  },[propertyStatsCombinedData])

  useEffect(() => {
    if (selectedDevicesGeoJson) {
      let devicesWithTime = {};
      selectedDevicesGeoJson.features.forEach((feature) => {
        const startTime = new Date(feature?.properties?.start_time);
        const endTime = new Date(feature?.properties?.end_time);
  
        if (isNaN(startTime) || isNaN(endTime)) return; // Skip invalid dates
  
        const timeDiffInMinutes = (endTime - startTime) / (1000 * 60);
  
        if (devicesWithTime[feature?.properties?.device_id]) {
          devicesWithTime[feature?.properties?.device_id] += timeDiffInMinutes;
        } else {
          devicesWithTime[feature?.properties?.device_id] = timeDiffInMinutes;
        }
      });
      const deviceWithMaxTime = Object.keys(devicesWithTime).reduce((maxDevice, currentDevice) => {
        return devicesWithTime[currentDevice] > devicesWithTime[maxDevice]
          ? currentDevice
          : maxDevice;
      }, Object.keys(devicesWithTime)[0]);
  
      if (deviceWithMaxTime) {
        fetchPropertyStats(deviceWithMaxTime); // Fetch stats for the device with the max time
      }
    }
  }, [selectedDevicesGeoJson]);

  return (
    <>
      <Typography
        fontWeight="600"
        variant={isSmallScreen ? "subtitle2" : "body2"}
        sx={{ color: blackShade1.main, marginBottom: "5px" }}
      >
        Property Entries/Exits
      </Typography>

      {isLoading ? (
        <>
          <Skeleton
            variant="rectangular"
            width="100%"
            animation="wave"
            height={170}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangular"
            width="30%"
            animation="wave"
            height={10}
            sx={{ borderRadius: "4px", marginTop: "10px" }}
          />
        </>
      ) : (
        <>
          <TableContainer
            className="propertySummary"
            sx={{ height: 160 }}
            component={Paper}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{fontSize:`${isSmallScreen ? "12px" : "14px"}` }} align="left">Entry Time</StyledTableCell>
                  <StyledTableCell style={{fontSize:`${isSmallScreen ? "12px" : "14px"}` }} align="center">Exit Time</StyledTableCell>
                  <StyledTableCell style={{fontSize:`${isSmallScreen ? "12px" : "14px"}` }} align="center">Time Spent</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {propertyStats &&
                  propertyStats?.map((stat, index) => {
                    const { start_time, end_time } = stat;
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          style={{ fontSize: `${isSmallScreen ? "11px" : "13px"}` }}
                          align="left"
                        >
                          {convertTimestampToFormattedDateForEntryExit(
                            start_time
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: `${isSmallScreen ? "11px" : "13px"}` }}
                          align="center"
                        >
                          {convertTimestampToFormattedDateForEntryExit(
                            end_time
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: `${isSmallScreen ? "11px" : "13px"}` }}
                          align="center"
                        >
                          {entryExitTimeDifference(start_time, end_time)}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default EntryExitTable;
