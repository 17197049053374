import { Box, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  formatTotalTimeFromSeconds,
  parseTimeString,
} from "../../../utils/common";
import { getCustomColor } from "../../../utils/theme";
import { IoInformationCircle } from "react-icons/io5";

const TimeSummarySection = ({
  timeSummary,
  totalTransitTimeInSeconds,
  totalIdleTimeInSeconds,
  totalMissingTimeInSeconds,
  totalVisits,
  isSmallScreen
}) => {
  const blackShade1 = getCustomColor("blackShade1");
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const timefunctioncalculateTotalProductionTime = (timeSummary) => {
    let totalSeconds = 0;

    // Loop through each beacon type and add its time to the totalObject.keys(timeSummary).forEach(beaconType => {
    Object.keys(timeSummary).forEach((beaconType) => {
      const timeString = timeSummary[beaconType];
      totalSeconds += parseTimeString(timeString);
    });

    return totalSeconds;
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: `${isSmallScreen ? "5px" : "30px"}`,
        marginTop: "10px",
        flexDirection: `${isSmallScreen ? "column" : "row"}`,
        // justifyContent: "space-around",
      }}
    >
      <Box>
        <Typography
          sx={{ color: blackShade1.main }}
          variant={isSmallScreen ? "caption" : "body2"}
          fontWeight="600"
          component="span"
        >
          Total Visits :{" "}
        </Typography>
        <Typography variant={isSmallScreen ? "caption" : "body2"} component="span">
          {totalVisits && totalVisits?.length}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography
          sx={{ color: blackShade1.main }}
          variant={isSmallScreen ? "caption" : "body2"}
          fontWeight="600"
          component="span"
        >
          Total Man Hours :{" "}
        </Typography>
        <Typography variant={isSmallScreen ? "caption" : "body2"} component="span">
          {formatTotalTimeFromSeconds(
              totalIdleTimeInSeconds +
              timefunctioncalculateTotalProductionTime(timeSummary)
          )}
        </Typography>

        <IoInformationCircle
          color="grey"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ padding: "10px", width: "180px" }}>
            {/* <Box className="tooltipContainer">
              <Typography
                sx={{ color: blackShade1.main }}
                variant="caption"
                fontWeight="600"
                component="span"
              >
                Transit Time :{" "}
              </Typography>
              <Typography variant="caption" component="span">
                {formatTotalTimeFromSeconds(totalTransitTimeInSeconds)}
              </Typography>
            </Box> */}
            <Box className="tooltipContainer">
              <Typography
                sx={{ color: blackShade1.main }}
                variant="caption"
                fontWeight="600"
                component="span"
              >
                Idle Time :{" "}
              </Typography>
              <Typography variant="caption" component="span">
                {formatTotalTimeFromSeconds(totalIdleTimeInSeconds)}
              </Typography>
            </Box>
            {/* <Box className="tooltipContainer">
              <Typography
                sx={{ color: blackShade1.main }}
                variant="caption"
                fontWeight="600"
                component="span"
              >
                Missing Time :{" "}
              </Typography>
              <Typography variant="caption" component="span">
                {formatTotalTimeFromSeconds(totalMissingTimeInSeconds)}
              </Typography>
            </Box> */}
            <Box className="tooltipContainer">
              <Typography
                sx={{ color: blackShade1.main }}
                variant="caption"
                fontWeight="600"
                component="span"
              >
                Production Time :{" "}
              </Typography>
              <Typography variant="caption" component="span">
                {timeSummary &&
                  formatTotalTimeFromSeconds(
                    timefunctioncalculateTotalProductionTime(timeSummary)
                  )}
              </Typography>
            </Box>
          </Box>
        </Popover>
      </Box>

      <Box>
        <Typography
          sx={{ color: blackShade1.main }}
          variant={isSmallScreen ? "caption" : "body2"}
          fontWeight="600"
          component="span"
        >
          Production Time :{" "}
        </Typography>
        <Typography variant={isSmallScreen ? "caption" : "body2"} component="span">
          {timeSummary &&
            formatTotalTimeFromSeconds(
              timefunctioncalculateTotalProductionTime(timeSummary)
            )}
        </Typography>
      </Box>
    </Box>
  );
};

export default TimeSummarySection;
