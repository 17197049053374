import {
  Autocomplete,
  Box,
  Divider,
  Drawer,
  FormControl,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Popper,
  Select,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomColor } from "../../../utils/theme";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { FaCalendar, FaSearch } from "react-icons/fa";
import {
  useGetIntelLocationGroupsMutation,
  useLazyGetDevicesBaseLayersQuery,
  useLazyGetOrganizationDevicesRoutesProductionQuery,
} from "../../../services/Properties";
import { setLocationGroupData } from "../../../slices/workPlanSlice";
import {
  setBeaconTypeColors,
  setBoundingBoxForDevices,
  setDeviceBaseLayersGeojson,
  setDevicesBeconCheckboxes,
  setDevicesDashboardLocationValue,
  setDevicesEquipmentData,
  setDevicesSummaryRef,
  setDevicesUniqueColors,
  setIsBeaconBasedColor,
  setIsDevicesBaseLayersLoading,
  setIsDevicesRouteDataLoading,
  setSelectedDateForDevices,
  setSelectedDevicesDashboard,
  setSelectedDevicesGeoJson,
  setSelectedLocationParcelValue,
} from "../../../slices/gisDashboardSlice";
import {
  beaconColors,
  calculateTimeDifferenceInSeconds,
  devicesColors,
  formatTotalTimeFromSeconds,
  getDateForApi,
  getDateFromCalender,
  getServiceColorFromLocationKey,
} from "../../../utils/common";
import DayCalender from "../../../components/DayCalender/DayCalender";
import DevicesSummaryCard from "./DevicesSummaryCard";
import PropertySummary from "./PropertySummary";
import { Mixpanel } from "../../../utils/mixPanel";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const DevicesDashboardSidebar = ({
  isPlannerDrawerOpen,
  togglePlannerDrawer,
}) => {
  const greenShade1 = getCustomColor("greenShade1");
  const blackShade1 = getCustomColor("blackShade1");
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const locationfromParams = searchParams.get("location");
  const dateFromParams = searchParams.get("date");
  const devicesSummaryRef = useSelector(
    (state) => state.gisDashboard.devicesSummaryRef
  );
  const captureRef = useRef(null);

  const selectedLocationParcelValue = useSelector(
    (state) => state.gisDashboard.selectedLocationParcelValue
  );
  const isDevicesBaseLayersLoading = useSelector(
    (state) => state.gisDashboard.isDevicesBaseLayersLoading
  );
  const isDevicesRouteDataLoading = useSelector(
    (state) => state.gisDashboard.isDevicesRouteDataLoading
  );
  const devicesDashboardLocationValue = useSelector(
    (state) => state.gisDashboard.devicesDashboardLocationValue
  );

  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );
  // const [isBaseLayersLoading, setIsBaseLayersLoading] = useState(false);
  const width = useViewportWidth();
  const user = JSON.parse(localStorage.getItem("user"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const boundingBoxForDevices = useSelector(
    (state) => state.gisDashboard.boundingBoxForDevices
  );
  const boundsForDevices = useSelector(
    (state) => state.gisDashboard.boundsForDevices
  );
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );
  const [getIntelLocationGroups, { isLoading }] =
    useGetIntelLocationGroupsMutation();
  const [getDevicesBaseLayers] = useLazyGetDevicesBaseLayersQuery();
  const [getOrganizationDevicesRoutesProduction] =
    useLazyGetOrganizationDevicesRoutesProductionQuery();

  const dispatch = useDispatch();
    useEffect(() => {
      (async () => {
        try {
          const locationData = await getIntelLocationGroups(
            user?.organization
          ).unwrap();
          dispatch(setLocationGroupData(locationData?.results));
          localStorage.setItem(
            "locationGroups",
            JSON.stringify(locationData?.results)
          );
        } catch (error) {
          toast.error(error?.data?.detail, {
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })();
    }, []);
  useEffect(() => {
    dispatch(setIsDevicesBaseLayersLoading(true));
    if (locationfromParams && dateFromParams && locationGroupData) {
      const date = dayjs(dateFromParams);
      dispatch(setSelectedDateForDevices(date));
      // Find the object in locationGroupData that matches locationfromParams
      const matchingLocation = locationGroupData?.find(
        (location) => location.key === locationfromParams
      );

      if (matchingLocation) {
        dispatch(setDevicesDashboardLocationValue(matchingLocation));
        fetchRouteData();
        fetchBaseLayers(matchingLocation?.key);
      } else {
        // Optionally handle cases where the location isn't found
        console.warn("No matching location found for locationfromParams");
      }

    }
  }, [locationGroupData]);



  const fetchBaseLayers = async (selectedLocationValue) => {
    if (selectedLocationValue) {
      dispatch(setIsDevicesBaseLayersLoading(true));
      try {
        const response = await getDevicesBaseLayers({
          orgName: user?.organization,
          location: `${selectedLocationValue?.toUpperCase()}`,
        });
        if (response?.data) {
          let baseGeojson = {
            type: "FeatureCollection",
            features: [],
          };
          response?.data?.results?.features?.map((feature) => {
            if (feature?.properties?.key?.includes("landscape-parcel")) {
              dispatch(setBoundingBoxForDevices(feature?.bbox));
              dispatch(setSelectedLocationParcelValue(feature?.properties?.key))
            } else {
              let featureObj = {
                type: "Feature",
                properties: {
                  serviceName: getServiceColorFromLocationKey(
                    feature?.properties.key
                  ),
                },
                geometry: feature?.geometry,
              };
              return baseGeojson.features.push(featureObj);
            }
          });
          dispatch(setDeviceBaseLayersGeojson(baseGeojson));
          dispatch(setIsDevicesBaseLayersLoading(false));
        }
      } catch (error) {
        dispatch(setIsDevicesBaseLayersLoading(false));
      }
    }
  };
  const fetchRouteData = async (url = null, accumulatedData = []) => {
    dispatch(setIsDevicesRouteDataLoading(true));
    try {
      const response = await getOrganizationDevicesRoutesProduction({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        location_keys: selectedLocationParcelValue,
        // bbox: boundingBoxForDevices
        //   ? `${boundingBoxForDevices[0]},${boundingBoxForDevices[1]},${boundingBoxForDevices[2]},${boundingBoxForDevices[3]}`
        //   : `${boundsForDevices[0]},${boundsForDevices[1]},${boundsForDevices[2]},${boundsForDevices[3]}`,
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchRouteData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          Mixpanel.identify(user.email);
          Mixpanel.track(`Viewing property`, {
            date: getDateFromCalender(selectedDateForDevices),
            property: devicesDashboardLocationValue?.name,
          });
          dispatch(
            setSelectedDevicesGeoJson({
              ...geoJsonData,
              features: combinedData,
            })
          );

          const uniqueDevices = {};
          const uniqueDevicesColors = {};
          const beaconTypes = {};
          const beaconTypeColors = {};
          let deviceColorIndex = 0;
          let beaconColorIndex = 0;

          combinedData.forEach((feature) => {
            const deviceId = feature?.properties?.device_id;
            const beaconType = feature?.properties?.beacon_type;

            if (!beaconTypes[beaconType]) {
              beaconTypes[beaconType] = true;
              beaconTypeColors[beaconType] = beaconColors[beaconColorIndex];
              beaconColorIndex = (beaconColorIndex + 1) % beaconColors.length;
            }
            if (!uniqueDevices[deviceId]) {
              uniqueDevices[deviceId] = true;
              uniqueDevicesColors[deviceId] = devicesColors[deviceColorIndex];
              deviceColorIndex = (deviceColorIndex + 1) % devicesColors.length;
            }
          });
          // Dispatch actions after data aggregation
          dispatch(setDevicesUniqueColors(uniqueDevicesColors));
          dispatch(setSelectedDevicesDashboard(uniqueDevices));
          dispatch(setBeaconTypeColors(beaconTypeColors));

          const result = combinedData.reduce((acc, item) => {
            const deviceId = item.properties.device_id;
            const beaconId = item.properties.beacon_id;

            // Check if the deviceId exists in the accumulator
            if (!acc[deviceId]) {
              acc[deviceId] = {};
            }

            // Add beaconId to the device object if it doesn't already exist
            if (!acc[deviceId][beaconId]) {
              acc[deviceId][beaconId] = true;
            }

            return acc;
          }, {});

          dispatch(setDevicesBeconCheckboxes(result));


          // Function to convert the date to a readable format
          function formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            });
          }

          const groupedByDeviceAndBeacon = combinedData.reduce((acc, item) => {
            const {
              device_id,
              beacon_id,
              beacon_name,
              beacon_type,
              start_time,
              end_time,
            } = item.properties;
            const timeInSeconds = calculateTimeDifferenceInSeconds(start_time, end_time);

            if (!acc[device_id]) {
              acc[device_id] = {};
            }

            if (!acc[device_id][beacon_id]) {
              acc[device_id][beacon_id] = {
                beacon_name,
                beacon_type,
                total_time_in_seconds: 0,
                start_time: formatDate(start_time),
                end_time: formatDate(end_time),
              };
            }

            acc[device_id][beacon_id].total_time_in_seconds += timeInSeconds;
            acc[device_id][beacon_id].end_time = formatDate(end_time);

            return acc;
          }, {});

          
          // Format the final object
          const finalResult = Object.keys(groupedByDeviceAndBeacon).reduce(
            (acc, deviceId) => {
              acc[deviceId] = Object.keys(
                groupedByDeviceAndBeacon[deviceId]
              ).map((beaconId) => {
                const beacon = groupedByDeviceAndBeacon[deviceId][beaconId];
                return {
                  beacon_id: beaconId,
                  beacon_name: beacon.beacon_name,
                  beacon_type: beacon.beacon_type,
                  total_time: formatTotalTimeFromSeconds(beacon.total_time_in_seconds),
                  start_time: beacon.start_time,
                  end_time: beacon.end_time,
                };
              });
              return acc;
            },
            {}
          );

          // Dispatch final grouped data
          dispatch(setDevicesEquipmentData(finalResult));
          dispatch(setIsDevicesRouteDataLoading(false));
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
      dispatch(setIsDevicesRouteDataLoading(false));
    }
  };

  useEffect(() => {
    if (selectedDateForDevices&&selectedLocationParcelValue) {
      fetchRouteData();
    }
  }, [selectedDateForDevices, selectedLocationParcelValue]);

  const handleLocationSelectChange = (e, newValue) => {
    const value = newValue ? newValue : null;
    dispatch(setDevicesDashboardLocationValue(newValue));
    if (newValue) {
      const params = new URLSearchParams(window.location.search);
      params.set("location", newValue.key);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState(null, "", newUrl);
    }
    fetchBaseLayers(newValue?.key);
  };

  const handleCalendarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const handleSelectedDate = (date) => {
    if (date) {
      const params = new URLSearchParams(window.location.search);
      params.set("date", getDateForApi(date));
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState(null, "", newUrl);
      dispatch(setSelectedDateForDevices(date));
    }
  };

  useEffect(() => {
    if (captureRef?.current) {
      dispatch(setDevicesSummaryRef(captureRef.current));
    }
  }, [captureRef]);
  const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-paper": {
      width: "300px !important", // Set custom width for the dropdown menu
    },
  });

  return (
    <Drawer
      anchor="left"
      open={isPlannerDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: width < 768 ? "100vw" : "35vw",
          zIndex: 10,
        },
      }}
    >
      <Box className="sidebarDrawer">
        {width < 768 && (
          <Box className="sidebarCloseButton" sx={{ margin: "10px 10px 0 0" }}>
            <IoClose size={24} onClick={() => togglePlannerDrawer()} />
          </Box>
        )}
        <Box className="sidebarSelectContainer">
          <FormControl fullWidth={width < 500 ? true : false}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={locationGroupData || []}
              getOptionLabel={(option) => (option ? option.name : "")}
              value={devicesDashboardLocationValue || null}
              onChange={handleLocationSelectChange}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Location"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaSearch fontSize="12px" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px", // Smaller label text
                    },
                  }}
                />
              )}
              sx={{
                background: greenShade1.main,
                color: (theme) => theme.palette.primary.dark,
                fontWeight: 600,
                fontSize: "12px", // Smaller font size for input
                width: width < 500 ? "auto" : "180px", // Adjust width
                border: "none",
                outline: "none",
              }}
              isOptionEqualToValue={(option, value) =>
                option && value && option.key === value
              }
              PopperComponent={StyledPopper}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontSize: "15px", // Smaller option text
                    padding: "4px 6px", // Adjust padding for smaller options
                  }}
                >
                  {option.name}
                </li>
              )}
            />
          </FormControl>
          <Box
            className="calenderButton"
            onClick={handleCalendarClick}
            sx={{ background: greenShade1.main }}
          >
            <Typography
              component="div"
              fontWeight="600"
              fontSize="12px"
              sx={{ color: "primary.dark" }}
              variant="subtitle2"
            >
              {selectedDateForDevices
                ? getDateFromCalender(selectedDateForDevices)
                : "Select Date"}
            </Typography>
            {<FaCalendar color="#326703" fontSize={16} />}
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCalendarClose}
            disableScrollLock={true}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <DayCalender
              dayCalenderValue={selectedDateForDevices}
              handleDayCalender={handleSelectedDate}
            />
          </Menu>
        </Box>
        <Divider />
      </Box>
      {selectedDevicesDashboard &&
        Object.keys(selectedDevicesDashboard).length > 0 && <PropertySummary />}
      <Box className="summaryWrapper">
        <Box ref={captureRef} className="summaryContainer">
          {(isDevicesBaseLayersLoading || isDevicesRouteDataLoading) ? (
            [...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                animation="wave"
                height={400}
                sx={{ borderRadius: "4px" }}
              />
            ))
          ) : selectedDevicesDashboard &&
            Object.keys(selectedDevicesDashboard).length > 0 ? (
            Object.keys(selectedDevicesDashboard).map((key) => {
              return <DevicesSummaryCard deviceName={key} key={key} />;
            })
          ) : (
            <div className="noDataForCrewIntel">
              <Typography variant="body1">No data available</Typography>
            </div>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DevicesDashboardSidebar;
