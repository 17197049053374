import React, { useEffect, useState } from "react";
import DevicesLiveLocationMap from "./DevicesLiveLocationMap";
import { useLazyGetDevicesQuery, useLazyGetDevicesBatteryStatusQuery } from "../../services/Properties";
import DevicesDetailsPopup from "./DevicesDetailsPopup";
import "./LiveDevicesScreen.css";

const LiveDevicesScreen = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [devices, setDevices] = useState([])
  const [deviceStatus, setDeviceStatus] = useState([]);

  const [getDevices] = useLazyGetDevicesQuery();
  const [getDevicesBatteryStatus] = useLazyGetDevicesBatteryStatusQuery();

  useEffect(() => {
    const fetchAndMergeData = async () => {
      if (!user?.organization) return;
      try {
        const [devicesResponse, batteryStatusResponse] = await Promise.all([
          getDevices(user.organization),
          getDevicesBatteryStatus(user.organization),
        ]);

        const devicesData = devicesResponse?.data?.results || [];
        const batteryStatusData = batteryStatusResponse?.data?.results || [];

        // Create a hashmap for battery status for O(1) lookups
        const batteryStatusMap = batteryStatusData.reduce((acc, batteryDevice) => {
          acc[batteryDevice.device] = batteryDevice.feed_data[0]?.value;
          return acc;
        }, {});

        const mergedDeviceStatus = devicesData.map((device) => ({
          device: device.device_id,
          batteryStatus: batteryStatusMap[device.device_id] >= 128 ? "Charging" : "Not Charging",
          batteryValue: batteryStatusMap[device.device_id] >= 128 ? 128 - batteryStatusMap[device.device_id] : batteryStatusMap[device.device_id],
          lastSeen: device.last_seen,
          isActive: device?.is_active
        }));
        setDevices(devicesData)
        setDeviceStatus(mergedDeviceStatus);
      } catch (error) {
        console.error("Error fetching and merging data:", error);
      }
    };
    fetchAndMergeData();
  }, [user?.organization, getDevices, getDevicesBatteryStatus]);

  return (
    <div>
      <DevicesLiveLocationMap devices={devices} setDevices={setDevices} />
      <DevicesDetailsPopup devices={deviceStatus} />
    </div>
  );
};

export default LiveDevicesScreen;
