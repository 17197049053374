import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import { IoIosPin, IoMdArrowDropright } from "react-icons/io";
import {
  capitalizeFirstLetter,
  getDateForApi,
  truncateString,
} from "../../utils/common";
import { RotateLoader } from "react-spinners";
import DevicesDashboardMap from "./DevicesDashboardMap";
import DevicesDashboardSidebar from "./Components/DevicesDashboardSidebar";
import {
  setDevicesMapRef,
  setIsBeaconBasedColor,
  setSelectedDevicesIdleGeoJson,
  setSelectedDevicesMissingGeoJson,
  setSelectedDevicesTransitGeoJson,
} from "../../slices/gisDashboardSlice";
import {
  useLazyGetOrganizationDevicesRoutesIdleQuery,
  useLazyGetOrganizationDevicesRoutesMissingDataQuery,
  useLazyGetOrganizationDevicesRoutesTransitQuery,
} from "../../services/Properties";
import { GiMove } from "react-icons/gi";
import { TiArrowSortedDown } from "react-icons/ti";
import BeaconLayersControlDrawer from "./Components/BeaconLayersControlDrawer";
import "./DevicesDashboard.css";
import { getCustomColor } from "../../utils/theme";
import useViewportWidth from "../../Hooks/useViewportWidth";
import Lottie from "lottie-react";
import mapLoader from "../../Svg/mowerLoader.json";

const DevicesDashboard = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const drawerRef = useRef();
  const dispatch = useDispatch();
  const width = useViewportWidth();
  const user = JSON.parse(localStorage.getItem("user"));
  const devicesMapRef = useSelector(
    (state) => state.gisDashboard.devicesMapRef
  );
  const captureRef = useRef(devicesMapRef);
  const selectedLocationParcelValue = useSelector(
    (state) => state.gisDashboard.selectedLocationParcelValue
  );
  const isDevicesBaseLayersLoading = useSelector(
    (state) => state.gisDashboard.isDevicesBaseLayersLoading
  );
  const isDevicesRouteDataLoading = useSelector(
    (state) => state.gisDashboard.isDevicesRouteDataLoading
  );
  const selectedLocationValue = useSelector(
    (state) => state.gisDashboard.selectedLocationValue
  );
  const isBeaconBasedColor = useSelector(
    (state) => state.gisDashboard.isBeaconBasedColor
  );
  const [getOrganizationDevicesRoutesIdle] =
    useLazyGetOrganizationDevicesRoutesIdleQuery();
  const [getOrganizationDevicesRoutesTransit] =
    useLazyGetOrganizationDevicesRoutesTransitQuery();
  const [getOrganizationDevicesRoutesMissingData] =
    useLazyGetOrganizationDevicesRoutesMissingDataQuery();
  const [drawerHeight, setDrawerHeight] = useState(0);
  const [isLayerControlDrawerOpen, setIsLayerControlDrawerOpen] =
    useState(false);
  const [isTimelineDrawerOpen, setIsTimelineDrawerOpen] = useState(false);
  const [isPlannerDrawerOpen, setIsPlannerDrawerOpen] = useState(true);
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );

  const handleLayerControlsClick = () => {
    setIsLayerControlDrawerOpen(!isLayerControlDrawerOpen);
  };

  const togglePlannerDrawer = () => {
    setIsPlannerDrawerOpen(!isPlannerDrawerOpen);
  };

  useEffect(() => {
    // Ensure the drawerRef is currently pointing to an element
    const drawerElement = drawerRef.current;
    if (!drawerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDrawerHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(drawerElement);

    // Cleanup function to safely unobserve
    return () => {
      if (drawerElement) {
        resizeObserver.unobserve(drawerElement);
      }
    };
  }, [isTimelineDrawerOpen]);

  const fetchIdleRouteData = async (url = null, accumulatedData = []) => {
    try {
      const response = await getOrganizationDevicesRoutesIdle({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        location_keys: selectedLocationParcelValue,
        // bbox: boundingBoxForDevices
        //   ? `${boundingBoxForDevices[0]},${boundingBoxForDevices[1]},${boundingBoxForDevices[2]},${boundingBoxForDevices[3]}`
        //   : `${boundsForDevices[0]},${boundsForDevices[1]},${boundsForDevices[2]},${boundsForDevices[3]}`,
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchIdleRouteData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          dispatch(
            setSelectedDevicesIdleGeoJson({
              ...geoJsonData,
              features: combinedData,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
    }
  };
  const fetchTansitRouteData = async (url = null, accumulatedData = []) => {
    try {
      const response = await getOrganizationDevicesRoutesTransit({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchTansitRouteData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          dispatch(
            setSelectedDevicesTransitGeoJson({
              ...geoJsonData,
              features: combinedData,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
    }
  };
  const fetchMissingRouteData = async (url = null, accumulatedData = []) => {
    try {
      const response = await getOrganizationDevicesRoutesMissingData({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        location_keys: selectedLocationParcelValue,
        // bbox: boundingBoxForDevices
        //   ? `${boundingBoxForDevices[0]},${boundingBoxForDevices[1]},${boundingBoxForDevices[2]},${boundingBoxForDevices[3]}`
        //   : `${boundsForDevices[0]},${boundsForDevices[1]},${boundsForDevices[2]},${boundsForDevices[3]}`,
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchMissingRouteData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          dispatch(
            setSelectedDevicesMissingGeoJson({
              ...geoJsonData,
              features: combinedData,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
    }
  };

  useEffect(() => {
    if (captureRef?.current) {
      dispatch(setDevicesMapRef(captureRef.current));
    }
  }, [captureRef]);

  useEffect(() => {
    if (selectedDateForDevices && selectedLocationParcelValue) {
      fetchIdleRouteData();
      fetchTansitRouteData();
      fetchMissingRouteData();
    }
  }, [selectedLocationParcelValue, selectedDateForDevices]);

  

  return (
    <div className="mapPanelContainer">
      <div
        ref={captureRef}
        className="mapboxMapContainer"
        style={{ position: "relative" }}
      >
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <DevicesDashboardMap />
        </APIProvider>

        {(isDevicesBaseLayersLoading || isDevicesRouteDataLoading) && (
          <div className="loaderOverlay">
            <Lottie
        animationData={mapLoader}
        loop={true}
        className="gif-loader" 
        style={{
          top: `calc(50%)`,
        }}
      />
          </div>
        )}
      </div>
      <Box
        className="sidebarButton"
        sx={{ top: "50%", left: isPlannerDrawerOpen ? "35vw" : "0vw" }}
        onClick={togglePlannerDrawer}
      >
        <IoMdArrowDropright fontSize={26} />
      </Box>
      {selectedLocationValue && (
        <div className="plannerDetailsOpener">
          <IoIosPin fontSize={18} />
          <div className="siteNameOnTop">
            <Tooltip title={selectedLocationValue?.name} arrow>
              <Typography fontWeight={600} fontSize={14}>
                {capitalizeFirstLetter(selectedLocationValue?.name)
                  ? truncateString(
                      selectedLocationValue?.name?.toUpperCase(),
                      23
                    )
                  : "Sitename not found"}
              </Typography>
            </Tooltip>
          </div>
        </div>
      )}
      {selectedDateForDevices && (
        <>
          <button
            className="viewMapControls"
            onClick={handleLayerControlsClick}
          >
            <GiMove size={19} />{" "}
            <Typography
              ariant="body2"
              fontWeight="600"
              fontSize="14px"
              sx={{ color: blackShade1.main }}
            >
              Layer Controls
            </Typography>{" "}
            <TiArrowSortedDown />
          </button>
          <BeaconLayersControlDrawer
            isLayerControlDrawerOpen={isLayerControlDrawerOpen}
            handleLayerControlsClick={handleLayerControlsClick}
          />
        </>
      )}
      {selectedDevicesDashboard &&
        Object.keys(selectedDevicesDashboard).length > 0 && (
          <Box
            sx={{
              top: "10vh",
              right: width > 768 && "42vh",
              left: width < 768 && "2vh",
            }}
            className="layerToggleButton"
          >
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{
                color: blackShade1.main,
                fontSize: width > 768 ? "13px" : "11px",
              }}
              component={"span"}
            >
              Equipment {width > 768 && "layers"}
            </Typography>
            <Switch
              checked={isBeaconBasedColor}
              onChange={() =>
                dispatch(setIsBeaconBasedColor(!isBeaconBasedColor))
              }
            />
          </Box>
        )}

      <DevicesDashboardSidebar
        togglePlannerDrawer={togglePlannerDrawer}
        isPlannerDrawerOpen={isPlannerDrawerOpen}
      />
    </div>
  );
};

export default DevicesDashboard;
