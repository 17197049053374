import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../MapScreen/PlannerDetailsDrawer";
import { getCustomColor } from "../../../utils/theme";

const ProductionTimeTable = ({ timeSummary, isSmallScreen }) => {
  const blackShade1 = getCustomColor("blackShade1");

  return (
    <>
      <Typography
        fontWeight="600"
        sx={{ color: blackShade1.main, marginBottom: "5px" }}
        variant={isSmallScreen ? "subtitle2" : "body2"}
      >
        Service wise production
      </Typography>

      <TableContainer
        className="propertySummary"
        sx={{ height: 160 }}
        component={Paper}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{fontSize:`${isSmallScreen ? "12px" : "14px"}` }} align="center">Equipment Type</StyledTableCell>
              <StyledTableCell style={{fontSize:`${isSmallScreen ? "12px" : "14px"}` }} align="center">Total Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeSummary &&
              Object.keys(timeSummary).map((beaconType, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell
                      style={{ fontSize: `${isSmallScreen ? "11px" : "13px"}`}}
                      align="center"
                    >
                      {beaconType === "0.0"
                        ? "No Equipment"
                        : beaconType.replace("_", " ")}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: `${isSmallScreen ? "11px" : "13px"}` }}
                      align="center"
                    >
                      {timeSummary[beaconType]}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductionTimeTable;
