import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";
import useViewportWidth from "../../../Hooks/useViewportWidth";

function getDeckGlLayers(
  layerData,
  colorsData,
  setHoverInfo,
  isLayerVisible,
  width
) {
  if (!layerData || !colorsData) return [];
  return [
    new GeoJsonLayer({
      id: "geojson-devices-route",
      data: layerData,
      filled: true,
      getLineWidth: 0.2,
      opacity: 1,
      visible: isLayerVisible,
      getFillColor: (f) => {
        return getServiceColorRgb(f?.properties?.serviceName);
      },
      getLineColor: (f) => {
        return hexToRgb(colorsData[f?.properties?.device_id]);
      },
      lineWidthMinPixels: 2,
      glOptions: {
        preserveDrawingBuffer: true
      },
      pickable: width < 800 ? false : true,
      onHover: (info, event) => {
        if (info && info.object) {
          setHoverInfo(info);
        } else {
          setHoverInfo(null);
        }
      },
    }),
  ];
}

const DevicesRouteLayer = ({ layerData, colorsData, setHoverInfo, isLayerVisible }) => {
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();
  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        layerData,
        colorsData,
        setHoverInfo,
        isLayerVisible,
        width
      )}
      pickable={width < 800 ? false : true}
      typedArrayManagerProps = {width < 800 ? { overAlloc: 1, poolSize: 0 } : null}
      isVisible={isVisible}
    />
  );
};

export default DevicesRouteLayer;
