import React, { useEffect, useState } from "react";
import "./Properties.css";
import { useNavigate } from "react-router-dom";
import { useLazyGetOrganizationDetailsQuery } from "../../services/Auth";
import ParcelTable from "./Components/ParcelTable";
import SavedPlans from "./Components/SavedPlans";
import {
  setDefaultUnits,
  setIsAllProductsPermission,
  setProductSelected,
} from "../../slices/globalSlice";
import { useDispatch } from "react-redux";
import ProductSelectionModal from "../../Modals/ProductSelectionModal/ProductSelectionModal";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import mapLoader from "../../Svg/mowerLoader.json";
import Lottie from "lottie-react";

const Properties = () => {
  const [isProductSelectModalOpen, setIsProductSelectModalOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const SelectedProduct = localStorage.getItem("SelectedProduct");
  const [getOrganizationDetails] = useLazyGetOrganizationDetailsQuery();

  const fetchAndSetOrganizationDetails = async (organization) => {
    try {
      const data = await getOrganizationDetails(organization).unwrap();
      dispatch(setDefaultUnits(data?.meta_data?.defaultUnits));

      localStorage.setItem("metaData", JSON.stringify(data?.meta_data));
      if (data?.meta_data?.application_services) {
        const { crew_intel, crew_planner } = data?.meta_data?.application_services;

        if (crew_intel && crew_planner) {
          dispatch(setIsAllProductsPermission(true));
          setIsProductSelectModalOpen(true);
        } else if (crew_intel) {
          dispatch(setProductSelected("crew_intel"));
          navigate("/intel-dashboard");
        } else if (crew_planner) {
          dispatch(setProductSelected("crew_planner"));
          navigate("/");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while fetching organization details", {
        autoClose: 1000,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (!user?.organization) {
      navigate("/newuser", { replace: true });
    }
  }, [navigate, user]);

  useEffect(() => {
    if (!SelectedProduct) {
      fetchAndSetOrganizationDetails(user?.organization);
    } else {
      setLoading(false); // Stop loading if SelectedProduct exists
    }
  }, [SelectedProduct, user?.organization]);

  if (loading) {
    return (
      <Lottie
        animationData={mapLoader}
        loop={true}
        className="gif-loader" 
        style={{
          top: `calc(50%)`,
        }}
      />
    );
  }

  return (
    <div className="propertiesContainer">
      {/* Uncomment your components here */}
      <SavedPlans />
      <ParcelTable />
      {isProductSelectModalOpen && (
        <ProductSelectionModal
          isOpen={isProductSelectModalOpen}
          setIsOpen={setIsProductSelectModalOpen}
        />
      )}
    </div>
  );
};

export default Properties;
