import { Box, Typography } from "@mui/material";
import React from "react";
import {
  capitalizeFirstLetter,
  convertSecondsToReadable,
} from "../../../utils/common";
import useViewportWidth from "../../../Hooks/useViewportWidth";

const CalendarEvent = ({ eventInfo }) => {
  const viewType = eventInfo.view.type; // Determine if the view is "dayGridWeek" or "dayGridMonth"
  const isSkeleton = eventInfo.event.extendedProps.isSkeleton;
  const witdh = useViewportWidth()
  const isMobile = witdh < 600;

  if (isSkeleton) {
    return (
      <Box sx={{ background: "white" }}>
        {[...Array(3)].map((_, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: "#f4f4f4",
              borderRadius: "8px",
              minHeight: isMobile ? "60px" : "80px",
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              margin: "10px 0",
            }}
          >
            <Box
              sx={{
                height: "10px",
                backgroundColor: "#ebebeb",
                borderRadius: "4px",
                marginBottom: "8px",
              }}
            />
            <Box
              sx={{
                height: isMobile ? "30px" : "40px",
                backgroundColor: "#ebebeb",
                borderRadius: "4px",
              }}
            />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: isMobile ? "5px" : "8px",
        boxShadow: "rgba(0, 0, 0, 0.02) 1px 0px 3px 1px",
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          fontSize: isMobile ? "10px" : "12px",
          color: "#1d4117",
          whiteSpace: "normal",
          overflow: "visible",
        }}
      >
        {capitalizeFirstLetter(eventInfo.event.title)}
      </Typography>
      {viewType === "dayGridWeek" && (
        <Box sx={{ marginTop: "5px", fontSize: isMobile ? "x-small" : "smaller", color: "#555" }}>
          {!isMobile && (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <img height="16px" width="16px" src="/images/Timer.png" />
                <Typography sx={{ fontSize: "10px", color: "#1d4117" }}>
                  {`${convertSecondsToReadable(eventInfo?.event?.extendedProps?.duration)}`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <img height="16px" width="16px" src="/images/User.png" />
                <Typography sx={{ fontSize: "10px", color: "#1d4117" }}>
                  {`${eventInfo?.event?.extendedProps?.task}`}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "5px",
              flexWrap: "nowrap",
              marginTop: "5px",
            }}
          >
            <img height="17px" width="17px" src="/images/Wrench.png" />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                flexGrow: 1,
                marginTop: "3px",
              }}
            >
              {eventInfo?.event?.extendedProps?.type
                .split(",")
                .map((word, index, arr) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: isMobile ? "8px" : "10px",
                      color: "#1d4117",
                      margin: "3px 0",
                      whiteSpace: "nowrap",
                      lineHeight: "0.2",
                    }}
                  >
                    {capitalizeFirstLetter(word.trim())}
                    {index < arr.length - 1 ? "," : ""}
                  </Typography>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CalendarEvent;
